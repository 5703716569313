import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";

const NotFoundPage: React.FC = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="w-5/6 max-w-3xl mx-auto">
      <h1 className="font-bold text-4xl">Not Found</h1>
      <p>bummer</p>
    </div>
  </Layout>
);

export default NotFoundPage;
